
import { defineComponent, ref } from "vue";
import { getTokenStatusReport } from "@/core/services/api/report";
import localstorage from "@/core/services/LocalstorageService";
import moment from "moment";

export default defineComponent({
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const searchTimeRange = {
      beginTime: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      endTime: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
    };
    let usingLength = ref(0);
    let FinishedLength = ref(0);

    const handleGetTokenStatusReport = async () => {
      let report = {
        usingTokensLength: 0,
        endTokensLength: 0,
      };
      await getTokenStatusReport(currentSpaceGuid!, searchTimeRange).then(
        (res) => {
          report.usingTokensLength = res.usingTokens.length;
          report.endTokensLength = res.endTokens.length;
        }
      );

      return report;
    };

    const init = async () => {
      let report = await handleGetTokenStatusReport();
      usingLength.value = report.usingTokensLength;
      FinishedLength.value = report.endTokensLength;
    };
    init();

    return {
      usingLength,
      FinishedLength,
    };
  },
});
