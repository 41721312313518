<template>
  <div
    class="container-fluid bg-white rounded p-0"
    style="min-height: 500px; max-height: 500px"
  >
    <div class="row justify-content-between">
      <div class="row col">
        <div class="col-6 my-6 ms-6 d-flex align-items-center">
          <h1 class="m-0">票券分析</h1>
        </div>
      </div>
      <div class="col-2 d-flex justify-content-center align-items-center">
        <button
          v-show="!isLoading"
          type="button"
          class="
            btn btn-sm btn-icon btn-color-primary btn-active-primary
            border-0
            me-n3
          "
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="media/icons/duotune/general/gen052.svg" />
          </span>
        </button>
        <DashboardTimeFilter
          :beginTime="showTimeRange.beginTime"
          :endTime="showTimeRange.endTime"
          :currentCycle="showTimeRange.currentCycle"
          @filterByLatest="filterByLatest"
          @filterByLatestMonth="filterByLatestMonth"
        ></DashboardTimeFilter>
        <div
          v-show="isLoading"
          class="spinner-grow align-self-center text-primary"
          style="width: 1.5rem; height: 1.5rem"
          role="status"
        ></div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-11">
        <div class="alert alert-warning" role="alert">
          以下圖表依據期間內所開立票券之「可用時長」作為分析
        </div>
      </div>
    </div>
    <div
      class="row d-flex justify-content-center align-items-center m-0"
      style="width: 100%; height: 350px"
    >
      <div class="col-11 overflow-auto">
        <div id="chart">
          <apexchart
            type="pie"
            width="360"
            :options="chartOptions"
            :series="series"
            style="font-size: 17px"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import { getValidTicket } from "@/core/services/api/tokens";
import localstorage from "@/core/services/LocalstorageService";
import moment from "moment";
import DashboardTimeFilter from "@/components/dropdown/DashboardTimeFilter.vue";

export default defineComponent({
  components: {
    DashboardTimeFilter,
  },
  setup() {
    let isNull = ref(false);
    let isLoading = ref(false);
    //是否顯示詳細時間
    let isShowDetailedTime = false;
    //顯示的過濾時間
    let showTimeRange = reactive({
      //當前的篩選週期 X月,近7天， 近3個月, 近6個月, 近12個月
      currentCycle: "",
      beginTime: "",
      endTime: "",
    });
    let timeRange = {
      beginTime: "",
      endTime: "",
    };
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let series = reactive([0, 0, 0, 0, 0]);
    let chartOptions = {
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " 張票";
          },
        },
      },
      chart: {
        width: 380,
        type: "pie",
      },

      labels: ["1 日內", " 1-7日", "8-30日", "31-90日", "91日以上"],
      responsive: [
        {
          breakpoint: 480,

          options: {
            chart: {
              width: 200,
            },

            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    let setFilterByLatest = async (latestTime, timeUnit, filterName) => {
      isLoading.value = true;
      isShowDetailedTime = true;
      timeRange = {
        beginTime: moment()
          .subtract(latestTime - 1, timeUnit)
          .startOf(timeUnit)
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      setShowTime(filterName);
      await setChartData();
      isLoading.value = false;
    };

    let setFilterByLatestMonth = async (monthString) => {
      isLoading.value = true;
      timeRange = {
        beginTime: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      isShowDetailedTime = false;

      setShowTime(monthString);
      await setChartData();
      isLoading.value = false;
    };

    //設置顯示時間
    let setShowTime = (filterName) => {
      showTimeRange.currentCycle = filterName;
      showTimeRange.beginTime = "";
      showTimeRange.endTime = "今日";
      let beginTime = moment(timeRange.beginTime);
      let endTime = moment(timeRange.endTime);
      let currentY = moment().format("YYYY");
      let current = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      if (beginTime.isBefore(currentY)) {
        showTimeRange.beginTime = beginTime.format("YYYY/M/D");
      } else {
        showTimeRange.beginTime = beginTime.format("M/D");
      }
      if (!endTime.isSame(current)) {
        showTimeRange.endTime = endTime.format("M/D");
      }
    };

    // 更新時間過濾
    let filterByLatest = async (filter) => {
      await setFilterByLatest(filter[0], filter[1], filter[2]);
    };
    let filterByLatestMonth = async (filter) => {
      await setFilterByLatestMonth(filter);
    };

    const handleGetValidTicket = async () => {
      return await getValidTicket(currentSpaceGuid!, timeRange);
    };

    const setChartData = async () => {
      let validTicket = await handleGetValidTicket();
      if (validTicket.length !== 0) {
        isNull.value = false;
        series.splice(0, series.length, ...[0, 0, 0, 0, 0]);
        validTicket.forEach((o) => {
          let beginTime = moment(o.beginTime, "YYYY-MM-DDThh:mm:ssZ");
          let endTime = moment(o.endTime, "YYYY-MM-DDThh:mm:ssZ");
          let timeDiff = endTime.diff(beginTime, "days", true);
          switch (true) {
            case timeDiff <= 1:
              series[0] = series[0] + 1;
              break;
            case timeDiff <= 7:
              series[1] = series[1] + 1;
              break;
            case timeDiff <= 30:
              series[2] = series[2] + 1;
              break;
            case timeDiff <= 90:
              series[3] = series[3] + 1;
              break;
            case timeDiff >= 91:
              series[4] = series[4] + 1;
              break;
          }
        });
      } else {
        isNull.value = true;
      }
    };

    const init = async () => {
      await setFilterByLatest(7, "days", "近7天");
      await setChartData();
    };
    init();

    return {
      chartOptions,
      series,
      showTimeRange,
      filterByLatest,
      filterByLatestMonth,
      isLoading,
    };
  },
});
</script>

<style>
</style>