export enum ReportItemType {
    Facility = "Facility", Equipment = "Equipment", Activity = "Activity"
}

export interface CurrentUsingReportItem {
    "type": ReportItemType,
    "itemGuid": string,
    "name": string,
    "total": number,
    "canUse": number,
    "using": number,
    "booking": number,
    "reserve": number
  }