<template>
  <div
    class="row align-items-center bg-white rounded"
    style="min-height: 130px"
  >
    <span class="svg-icon svg-icon-2 col-2 ps-7">
      <inline-svg src="media\svg\icons\Dashboard\Group 147.svg" />
    </span>
    <h3 class="col mb-0 align-middle">目前成員數</h3>
    <div
      v-show="isLoading"
      class="spinner-grow align-self-center text-primary me-2"
      style="width: 1.5rem; height: 1.5rem"
      role="status"
    ></div>
    <div
      class="col-12 d-flex justify-content-center"
      style="font-size: 42px; font-weight: 600"
    >
      {{ membersCount }}
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, ref } from "vue";
import { getMembersBySpace } from "@/core/services/api/members";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  setup() {
    let isLoading = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let membersCount = ref(0);

    const getMembersCount = async () => {
      let members = await getMembersBySpace(currentSpaceGuid!);
      membersCount.value = members.length;
    };

    const init = async () => {
      isLoading.value = true;
      await getMembersCount();
      isLoading.value = false;
    };
    init();

    return {
      isLoading,
      membersCount,
    };
  },
});
</script>

<style>
</style>