
import { defineComponent, onMounted } from "vue";
import CurrentlyMembers from "@/views/dashboard/CurrentlyMembers.vue";
import UserFeedback from "@/views/dashboard/UserFeedback.vue";
import CurrentlyDevice from "@/views/dashboard/CurrentlyDevice.vue";
import OrderTotal from "@/views/dashboard/OrderTotal.vue";
import OrderAnalysisChart from "@/views/dashboard/OrderAnalysisChart.vue";
import FacilityFrequency from "@/views/dashboard/FacilityFrequency.vue";
import DeviceFrequency from "@/views/dashboard/DeviceFrequency.vue";
import MemberDistribution from "@/views/dashboard/MemberDistribution.vue";
import TicketAnalysis from "@/views/dashboard/TicketAnalysis.vue";
import FinancialAnalysis from "@/views/dashboard/FinancialAnalysis.vue";
import TodayVisitor from "@/views/dashboard/TodayVisitor.vue";
import Bulletin from "@/views/dashboard/Bulletin.vue";
import Collection from "@/views/dashboard/Collection.vue";
import NewMember from "@/views/dashboard/NewMember.vue";
import UserConsumptionAmount from "@/views/dashboard/UserConsumptionAmount.vue";
import LatestReviews from "@/views/dashboard/LatestReviews.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    CurrentlyMembers,
    UserFeedback,
    CurrentlyDevice,
    OrderTotal,
    OrderAnalysisChart,
    FacilityFrequency,
    DeviceFrequency,
    // MemberDistribution,
    TicketAnalysis,
    FinancialAnalysis,
    TodayVisitor,
    Bulletin,
    Collection,
    NewMember,
    UserConsumptionAmount,
    LatestReviews
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  },
});
