<template>
  <div
    class="row align-items-center rounded"
    style="min-height: 130px;background-color: rgb(179, 179, 179);"
  >
    <span class="svg-icon svg-icon-2 svg-icon-white col-2 ps-7" >
      <inline-svg src="media\svg\icons\Dashboard\Group 147.svg" />
    </span>
    
    <h3 class="col text-white mb-0 align-middle position-relative overflow-hidden">期間用戶回饋<i class="text-white" style="font-size: 12px;">(未開放)</i></h3>
    
    <div
      class="col-12 d-flex justify-content-center text-white"
      style="font-size: 42px; font-weight: 600"
    >
      0
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";

export default defineComponent({});
</script>
