
import { computed, defineComponent, ref } from "vue";
import moment from "moment";

export default defineComponent({
  props:["beginTime","endTime","currentCycle"],
  setup(props, { attrs, emit, slots }) {
    let setFilterByLatest = (latestTime, timeUnit, filterName) => {
      let data = [latestTime, timeUnit, filterName]
      emit("filterByLatest", data);
    };

    let setFilterByLatestMonth = (filter) => {
      emit("filterByLatestMonth", filter);
    }

    //取得月份
   let getMonthString = (front) => {
      let frontMonthString = moment()
        .subtract(front, "month")
        .startOf("month")
        .format("M");
      return frontMonthString + "月份";
    }


    
    return {
      setFilterByLatest,
      setFilterByLatestMonth,
      getMonthString,
      props

    };
  },
});
