<template>
  <div
    class="row align-items-center bg-white rounded"
    style="min-height: 130px"
  >
    <span class="svg-icon svg-icon-2 col-1 ps-7">
      <inline-svg src="media\svg\icons\Dashboard\Group 333.svg" />
    </span>
    <h3 class="col mb-0 align-middle">目前使用中成員</h3>
    <div class="row">
      <div class="col-xxl-5">
        <div style="text-align: center; font-size: 25px; color: #6993ff">
          {{ usingLength }}
        </div>
        <div style="text-align: center; color: #a4a4a4">空間使用中</div>
      </div>
      <div class="col-xxl-2">
        <img
          src="media\svg\icons\Dashboard\Line 34.svg"
          style="display: block; margin: auto"
        />
      </div>
      <div class="col-xxl-5">
        <div style="text-align: center; font-size: 25px; color: #f64e60">
          {{ FinishedLength }}
        </div>
        <div style="text-align: center; color: #a4a4a4">使用完畢</div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, ref } from "vue";
import { getTokenStatusReport } from "@/core/services/api/report";
import localstorage from "@/core/services/LocalstorageService";
import moment from "moment";

export default defineComponent({
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const searchTimeRange = {
      beginTime: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      endTime: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
    };
    let usingLength = ref(0);
    let FinishedLength = ref(0);

    const handleGetTokenStatusReport = async () => {
      let report = {
        usingTokensLength: 0,
        endTokensLength: 0,
      };
      await getTokenStatusReport(currentSpaceGuid!, searchTimeRange).then(
        (res) => {
          report.usingTokensLength = res.usingTokens.length;
          report.endTokensLength = res.endTokens.length;
        }
      );

      return report;
    };

    const init = async () => {
      let report = await handleGetTokenStatusReport();
      usingLength.value = report.usingTokensLength;
      FinishedLength.value = report.endTokensLength;
    };
    init();

    return {
      usingLength,
      FinishedLength,
    };
  },
});
</script>

<style>
</style>