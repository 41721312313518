
import { defineComponent, reactive, ref, onMounted } from "vue";
import DashboardTimeFilter from "@/components/dropdown/DashboardTimeFilter.vue";
import moment from "moment";
import localstorage from "@/core/services/LocalstorageService";
import { getPaymentReport } from "@/core/services/api/report";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
export default defineComponent({
  components: {
    DashboardTimeFilter,
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let timeRange = {
      beginTime: "",
      endTime: "",
    };
    //顯示的過濾時間
    let showTimeRange = reactive({
      //當前的篩選週期 X月,近7天， 近3個月, 近6個月, 近12個月
      currentCycle: "",
      beginTime: "",
      endTime: "",
    });
    //應收金額
    let onlineAmountReceivable = ref();
    //其他支付
    let otherAmountReceivable = ref();
    //未銷帳
    let unReceiveAmount = ref();
    let onlineAmount = ref();
    let otherAmount = ref();
    let isLoading = ref(false);

    let setFilterByLatestMonth = async (monthString) => {
      isLoading.value = true;
      timeRange = {
        beginTime: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      };

      setShowTime(monthString);
      await countPaymentAmount();
      isLoading.value = false;
    };

    let setFilterByLatest = async (latestTime, timeUnit, filterName) => {
      isLoading.value = true;
      timeRange = {
        beginTime: moment()
          .subtract(latestTime - 1, timeUnit)
          .startOf(timeUnit)
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      setShowTime(filterName);
      await countPaymentAmount();
      isLoading.value = false;
    };

    // 計算帳單金額
    let countPaymentAmount = async () => {
      let paymentReport = await handleGetPaymentReport();
      onlineAmountReceivable.value = (paymentReport.onlineAmountReceivable / 1000).toFixed(1);
      otherAmountReceivable.value = (paymentReport.otherAmountReceivable / 1000).toFixed(1);
      unReceiveAmount.value = (paymentReport.unReceiveAmount / 1000).toFixed(1);
    };
    // 取得期間內帳單
    let handleGetPaymentReport = async () => {
      let requestBody = timeRange;
      return await getPaymentReport(currentSpaceGuid!, requestBody);
    };
    //設置顯示時間
    let setShowTime = (filterName) => {
      showTimeRange.currentCycle = filterName;
      showTimeRange.beginTime = "";
      showTimeRange.endTime = "今日";
      let beginTime = moment(timeRange.beginTime);
      let endTime = moment(timeRange.endTime);
      let currentY = moment().format("YYYY");
      let current = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      if (beginTime.isBefore(currentY)) {
        showTimeRange.beginTime = beginTime.format("YYYY/M/D");
      } else {
        showTimeRange.beginTime = beginTime.format("M/D");
      }
      if (!endTime.isSame(current)) {
        showTimeRange.endTime = endTime.format("M/D");
      }
    };

    // 更新時間過濾
    let filterByLatest = async (filter) => {
      await setFilterByLatest(filter[0], filter[1], filter[2]);
    };
    let filterByLatestMonth = async (filter) => {
      await setFilterByLatestMonth(filter);
    };
    setFilterByLatest(7, "days", "近7天");
    return {
      isLoading,
      showTimeRange,
      onlineAmountReceivable,
      otherAmountReceivable,
      unReceiveAmount,
      filterByLatest,
      filterByLatestMonth,
    };
  },
});
