<template>
  <div
    class="row align-items-center rounded"
    style="min-height: 130px; background-color: #4ab58e"
  >
    <span class="svg-icon svg-icon-2 svg-icon-white col-1 ps-7">
      <inline-svg src="media\svg\icons\Dashboard\Group 162.svg" />
    </span>
    <h3 class="col mb-0 align-middle text-white">郵件/包裹代收統計</h3>
    <div v-if="isAlliance" class="row">
      <div class="col-xxl border-end">
        <div
          style="text-align: center; font-size: 25px; color: rgb(255, 255, 255)"
        >
          {{ newPackagesLength }}
        </div>
        <div style="text-align: center; color: rgb(226, 226, 226)">
          今日登記
        </div>
      </div>

      <div class="col-xxl border-end">
        <div
          style="text-align: center; font-size: 25px; color: rgb(255, 255, 255)"
        >
          {{ unReceivePackagesLength }}
        </div>
        <div style="text-align: center; color: rgb(226, 226, 226)">待領取</div>
      </div>

      <div class="col-xxl border-end">
        <div
          style="text-align: center; font-size: 25px; color: rgb(255, 255, 255)"
        >
          {{ unDepartPackagesLength }}
        </div>
        <div style="text-align: center; color: rgb(226, 226, 226)">待轉出</div>
      </div>

      <div class="col-xxl border-end">
        <div
          style="text-align: center; font-size: 25px; color: rgb(255, 255, 255)"
        >
          {{ departedPackagesLength }}
        </div>
        <div style="text-align: center; color: rgb(226, 226, 226)">轉送中</div>
      </div>

      <div class="col-xxl">
        <div
          style="text-align: center; font-size: 25px; color: rgb(255, 255, 255)"
        >
          {{ unArrivalPackagesLength }}
        </div>
        <div style="text-align: center; color: rgb(226, 226, 226)">待轉入</div>
      </div>
    </div>
    <div v-else  class="row">
      <div class="col-xxl border-end">
        <div
          style="text-align: center; font-size: 25px; color: rgb(255, 255, 255)"
        >
          {{ newPackagesLength }}
        </div>
        <div style="text-align: center; color: rgb(226, 226, 226)">
          今日登記
        </div>
      </div>

      <div  class="col-xxl">
        <div
          style="text-align: center; font-size: 25px; color: rgb(255, 255, 255)"
        >
          {{ unReceivePackagesLength }}
        </div>
        <div style="text-align: center; color: rgb(226, 226, 226)">待領取</div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, ref } from "vue";
import { getPackageReport } from "@/core/services/api/report";
import localstorage from "@/core/services/LocalstorageService";
import moment from "moment";

export default defineComponent({
  setup() {
    let isAlliance =
      localstorage.getCurrentSpaces()!.alliances.length > 0 ? true : false;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let timeRange = {
      beginTime: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      endTime: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
    };
    // let isAlliance = Vue.$ls.get("currentSpace").alliances.length>0? true:false,
    //當日新包裹數量
    let newPackagesLength = ref(0);
    //未領取包裹數量
    let unReceivePackagesLength = ref(0);
    //代轉出數量
    let unDepartPackagesLength = ref(0);
    //轉送中數量
    let departedPackagesLength = ref(0);
    //代轉入數量
    let unArrivalPackagesLength = ref(0);

    const init = async () => {
      await getPackageReport(currentSpaceGuid!, timeRange).then((res) => {
        newPackagesLength.value = res.newPackages.length;
        unReceivePackagesLength.value = res.unReceivePackages.length;
        unDepartPackagesLength.value = res.unDepartPackages.length;
        departedPackagesLength.value = res.departedPackages.length;
        unArrivalPackagesLength.value = res.unArrivalPackages.length;
      });
    };
    init();

    return {
      isAlliance,
      newPackagesLength,
      unReceivePackagesLength,
      unDepartPackagesLength,
      departedPackagesLength,
      unArrivalPackagesLength,
    };
  },
});
</script>

<style>
</style>