
import { defineComponent, reactive, ref, onMounted } from "vue";
import moment from "moment";
import localstorage from "@/core/services/LocalstorageService";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getFacilityReport } from "@/core/services/api/report";
import DashboardTimeFilter from "@/components/dropdown/DashboardTimeFilter.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { FacilityExtend } from "@/core/services/responsesModel/facilityExtend";
import { getFilePath } from "@/core/utils/file";

export default defineComponent({
  components: {
    DashboardTimeFilter,
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
    let showData: Array<any> = reactive([]);
    let facilities: Array<FacilityExtend>;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let rankingData = reactive([]);
    let communitySelected = ref("all");
    let communityOptions: any = reactive([]);
    let isLoading = ref(false);
    //是否顯示詳細時間
    let isShowDetailedTime = false;
    //顯示的過濾時間
    let showTimeRange = reactive({
      //當前的篩選週期 X月,近7天， 近3個月, 近6個月, 近12個月
      currentCycle: "",
      beginTime: "",
      endTime: "",
    });
    let timeRange = {
      beginTime: "",
      endTime: "",
    };

    let setFilterByLatest = async (latestTime, timeUnit, filterName) => {
      isLoading.value = true;
      isShowDetailedTime = true;
      timeRange = {
        beginTime: moment()
          .subtract(latestTime - 1, timeUnit)
          .startOf(timeUnit)
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      setShowTime(filterName);
      await setShowData();
      isLoading.value = false;
    };

    let setFilterByLatestMonth = async (monthString) => {
      isLoading.value = true;
      timeRange = {
        beginTime: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      isShowDetailedTime = false;

      setShowTime(monthString);
      await setShowData();
      isLoading.value = false;
    };

    //設置顯示時間
    let setShowTime = (filterName) => {
      showTimeRange.currentCycle = filterName;
      showTimeRange.beginTime = "";
      showTimeRange.endTime = "今日";
      let beginTime = moment(timeRange.beginTime);
      let endTime = moment(timeRange.endTime);
      let currentY = moment().format("YYYY");
      let current = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      if (beginTime.isBefore(currentY)) {
        showTimeRange.beginTime = beginTime.format("YYYY/M/D");
      } else {
        showTimeRange.beginTime = beginTime.format("M/D");
      }
      if (!endTime.isSame(current)) {
        showTimeRange.endTime = endTime.format("M/D");
      }
    };

    //取得月份
    let getMonthString = (front) => {
      let frontMonthString = moment()
        .subtract(front, "month")
        .startOf("month")
        .format("M");
      return frontMonthString + "月份";
    };

    // 更新時間過濾
    let filterByLatest = async (filter) => {
      await setFilterByLatest(filter[0], filter[1], filter[2]);
    };
    let filterByLatestMonth = async (filter) => {
      await setFilterByLatestMonth(filter);
    };

    const handleGetFacilityReport = async () => {
      return await getFacilityReport(currentSpaceGuid!, timeRange);
    };

    const setShowData = async () => {
      let data: any = [];
      let facilityReport = await handleGetFacilityReport();
      for (let i = 0; i < facilityReport.length; i++) {
        let facility = getFacilityByGuid(facilityReport[i].itemGuid);
        //取得設施照片
        let imageGuid = facility.facility.theme.images[0];
        let image = "";
        if (imageGuid !== null && imageGuid !== undefined) {
          image = getFilePath(imageGuid);
        }
        //計算設施使用率
        let ratio = Math.ceil(
          (facilityReport[i].timeRangeReportItem.booking /
            facilityReport[i].timeRangeReportItem.total) *
            100
        );
        data.push({
          img: image,
          title: facilityReport[i].name,
          ratio: ratio,
          class: "primary",
          percentage: ratio + "%",
        });
      }
      data.sort(function (a, b) {
        // boolean false == 0; true == 1
        return a.ratio - b.ratio;
      });

      data.reverse();
      showData.splice(0, showData.length, ...data);
    };

    const setFacility = async () => {
      facilities = await getFacilityBySpace(currentSpaceGuid!);
    };

    const getFacilityByGuid = (guid: string) => {
      return facilities.filter((o) => o.facility.guid === guid)[0];
    };

    const init = async () => {
      await setFacility();
      await setFilterByLatest(7, "days", "近7天");
      await setShowData();
    };

    init();

    return {
      rankingData,
      communityOptions,
      showTimeRange,
      filterByLatest,
      filterByLatestMonth,
      communitySelected,
      showData,
      isLoading,
    };
  },
});
