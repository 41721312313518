<template>
  <div
    class="container-fluid bg-white rounded p-0"
    style="
      min-height: 500px;
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;
    "
  >
    <div class="row justify-content-between">
      <div class="row col">
        <div class="col-6 my-6 ms-6 d-flex align-items-center">
          <h1 class="m-0">最新使用評價</h1>
        </div>
        <div class="col d-flex justify-content-end">
          <div
            v-show="isLoading"
            class="spinner-grow align-self-center text-primary"
            style="width: 1.5rem; height: 1.5rem"
            role="status"
          ></div>
        </div>
      </div>
    </div>
    <div class="row px-6">
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle gs-0 gy-3">
          <!--begin::Table head-->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-70px"></th>
              <th class="p-0 min-w-70px"></th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index1) in tableData" :key="index1">
              <tr>
                <td>
                  <div class="symbol symbol-50px">
                    <img
                      v-if="item.image == ''"
                      src="media/avatars/blank.png"
                      alt=""
                    />
                    <img v-else :src="item.image" alt="" />
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    >{{ item.userName }}</a
                  >
                  <span
                    v-if="item.userName == ''"
                    class="text-dark fw-bolder mb-1 fs-6"
                    >未填寫姓名</span
                  >
                </td>
                <td>
                  <span class="text-muted fw-bold d-block fs-7">評價設施</span>
                  <span class="text-dark fw-bolder d-block fs-5">{{
                    item.facility
                  }}</span>
                </td>
                <td>
                  <div class="d-flex align-items-center justify-content-end">
                    <div class="me-6">
                      <i class="fa fa-star-half-alt me-1 text-warning fs-5"></i>

                      <span class="text-gray-800 fw-bolder">{{
                        item.rating
                      }}</span>
                    </div>

                    <div
                     @click="toFeedbackList()"
                      class="
                        btn
                        btn-sm
                        btn-icon
                        btn-bg-light
                        btn-active-color-primary
                      "
                    >
                      <span class="svg-icon svg-icon-2">
                        <inline-svg
                          src="media/icons/duotune/arrows/arr064.svg"
                        />
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import { getSpaceFeedback } from "@/core/services/api/feedback";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import moment from "moment";
import { getMembersBySpace } from "@/core/services/api/members";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getFilePath } from "@/core/utils/file";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const feedbackList: any = reactive([]);
    const members: any = reactive([]);
    const facility: any = reactive([]);
    const tableData: any = reactive([]);
    const router = useRouter();
    const isLoading = ref(true);

    // 取得最新使用評價
    const handleGetSpaceFeedback = async () => {
      let request = {
        beginTime: getUtcTimeString(moment().subtract(1, "years")),
        endTime: getUtcTimeString(moment()),
      };
      return await getSpaceFeedback(currentSpaceGuid!, request);
    };

    // 根據評論更新時間排序
    const filterByRatingUpdateTime = (list) => {
      return list.sort((a, b) =>
        moment(b.ratingUpdateTime).diff(moment(a.ratingUpdateTime))
      );
    };

    // 取得設施名稱
    const getFacilityName = (guid) => {
      let facilityName = facility.filter((f) => f.facility.guid == guid)[0]
        .facility.name;
      return facilityName;
    };

    // 設置列表顯示資料
    const setTableData = async () => {
      isLoading.value = true
      let feedbackList = filterByRatingUpdateTime(
        await handleGetSpaceFeedback()
      );
      for (let index = 0;index < 6 && index < feedbackList.length; index++) {
        let member = getMemberByGuid(feedbackList[index].user);
        tableData.push({
          image: !member.theme.avatar ? "":getFilePath(member.theme.avatar),
          userName: member.name,
          facility: getFacilityName(feedbackList[index].facility),
          rating: feedbackList[index].rating,
        });
      }
      isLoading.value = false
    };

    // 取得空間成員
    const setMembersBySpace = async () => {
      let membersBySpace = await getMembersBySpace(currentSpaceGuid!);
      members.splice(0, members.length, ...membersBySpace);
    };

    // 根據guid取得空間成員
    const getMemberByGuid = (guid) => {
      let filterData = members.filter((member) => member.guid == guid);
      return filterData[0];
    };

    const toFeedbackList = () => {
      router.push({name:"userFeedbackList"})
    }

    const init = async () => {
      facility.splice(
        0,
        facility.length,
        ...(await getFacilityBySpace(currentSpaceGuid!))
      );
      await setMembersBySpace();
      await setTableData();
    };
    init();

    return {
      tableData,
      toFeedbackList,
      isLoading
    };
  },
});
</script>

<style>
</style>