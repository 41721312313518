
import localstorage from "@/core/services/LocalstorageService";
import { defineComponent, ref, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { getCurrentUsingReport } from "@/core/services/api/report";
import { ReportItemType } from "@/core/services/responsesModel/currentUsingReportItem";

export default defineComponent({
  setup() {
    let isLoading = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let lend = ref(0);
    let overdue = ref(0);

    const getUsingReport = async () => {
     await getCurrentUsingReport(currentSpaceGuid!).then((report) => {
        if (report !== null && report !== undefined) {
          let equipments = report.filter(
            (o) => o.type == ReportItemType.Equipment
          );
          let lendCount = 0;
          let usingCount = 0;
          for (const key in equipments) {
            lendCount = lendCount + equipments[key].booking;
            usingCount = usingCount + equipments[key].using;
          }
          lend.value = lendCount;
          if (usingCount > lendCount) {
            overdue.value = usingCount - lendCount;
          }
        }
      });
    };

    const init = async () => {
      isLoading.value = true;
      await getUsingReport();

      isLoading.value = false;
    };
    init();

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      isLoading,
      lend,
      overdue,
    };
  },
});
