
import { defineComponent, reactive, ref } from "vue";
import { getSpaceFeedback } from "@/core/services/api/feedback";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import moment from "moment";
import { getMembersBySpace } from "@/core/services/api/members";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getFilePath } from "@/core/utils/file";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const feedbackList: any = reactive([]);
    const members: any = reactive([]);
    const facility: any = reactive([]);
    const tableData: any = reactive([]);
    const router = useRouter();
    const isLoading = ref(true);

    // 取得最新使用評價
    const handleGetSpaceFeedback = async () => {
      let request = {
        beginTime: getUtcTimeString(moment().subtract(1, "years")),
        endTime: getUtcTimeString(moment()),
      };
      return await getSpaceFeedback(currentSpaceGuid!, request);
    };

    // 根據評論更新時間排序
    const filterByRatingUpdateTime = (list) => {
      return list.sort((a, b) =>
        moment(b.ratingUpdateTime).diff(moment(a.ratingUpdateTime))
      );
    };

    // 取得設施名稱
    const getFacilityName = (guid) => {
      let facilityName = facility.filter((f) => f.facility.guid == guid)[0]
        .facility.name;
      return facilityName;
    };

    // 設置列表顯示資料
    const setTableData = async () => {
      isLoading.value = true
      let feedbackList = filterByRatingUpdateTime(
        await handleGetSpaceFeedback()
      );
      for (let index = 0;index < 6 && index < feedbackList.length; index++) {
        let member = getMemberByGuid(feedbackList[index].user);
        tableData.push({
          image: !member.theme.avatar ? "":getFilePath(member.theme.avatar),
          userName: member.name,
          facility: getFacilityName(feedbackList[index].facility),
          rating: feedbackList[index].rating,
        });
      }
      isLoading.value = false
    };

    // 取得空間成員
    const setMembersBySpace = async () => {
      let membersBySpace = await getMembersBySpace(currentSpaceGuid!);
      members.splice(0, members.length, ...membersBySpace);
    };

    // 根據guid取得空間成員
    const getMemberByGuid = (guid) => {
      let filterData = members.filter((member) => member.guid == guid);
      return filterData[0];
    };

    const toFeedbackList = () => {
      router.push({name:"userFeedbackList"})
    }

    const init = async () => {
      facility.splice(
        0,
        facility.length,
        ...(await getFacilityBySpace(currentSpaceGuid!))
      );
      await setMembersBySpace();
      await setTableData();
    };
    init();

    return {
      tableData,
      toFeedbackList,
      isLoading
    };
  },
});
