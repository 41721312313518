
import { defineComponent, reactive, ref } from "vue";
import moment from "moment";
import localstorage from "@/core/services/LocalstorageService";
import { getTokenReport } from "@/core/services/api/report";

export default defineComponent({
  setup() {
    let isLoading = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let currentTime = moment().format("YYYY-MM-DDThh:mm:ssZ");
    let series: any = reactive([]);
    let reportData: any = [];
    let chartOptions = reactive({
      chart: {
        defaultLocale: "zh-cn",
        locales: [
          {
            name: "zh-cn",
            options: {
              months: [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月",
              ],
              shortMonths: [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月",
              ],
              days: [
                "星期天",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
              ],
              shortDays: [
                "周日",
                "周一",
                "周二",
                "周三",
                "周四",
                "周五",
                "周六",
              ],
              toolbar: {
                exportToSVG: "下載 SVG",
                exportToPNG: "下載 PNG",
                exportToCSV: "下載 CSV",
                menu: "菜單",
                selection: "選擇",
                selectionZoom: "選擇縮放",
                zoomIn: "放大",
                zoomOut: "縮小",
                pan: "平移",
                reset: "重置縮放",
              },
            },
          },
        ],
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      annotations: {
        yaxis: [
          {
            // y: 30,
            // borderColor: "#999",
            // label: {
            //   show: true,
            //   text: "Support",
            //   style: {
            //     color: "#fff",
            //     background: "#00E396",
            //   },
            // },
          },
        ],
        xaxis: [
          {
            x: new Date("01 Mar 2012").getTime(),
            borderColor: "#999",
            yAxisIndex: 0,
            label: {
              show: true,
              text: "Rally",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
            },
          },
        ],
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        min: moment().subtract(1, "years").valueOf(),
        tickAmount: 6,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
        // title: {
        //   text: '$ (thousands)'
        // }
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    });
    const chart: any = ref();
    let communitySelected = ref("all");
    let communityOptions: any = reactive([]);
    let selection = "Latest_12_months";

    let updateData = (timeline) => {
      selection = timeline;
      const latestMonthsBegin = new Date(
        moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ss")
      ).getTime();
      const latestMonthsEnd = new Date(
        moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ss")
      ).getTime();
      const thisMonthBegin = new Date(
        moment()
          .subtract(0, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ss")
      ).getTime();
      const latestSevenDays = new Date(
        moment()
          .subtract(7, "days")
          .startOf("days")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      ).getTime();
      const latestThreeMonths = new Date(
        moment()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DDThh:mm:ss")
      ).getTime();
      const latestSixMonths = new Date(
        moment()
          .subtract(6, "month")
          .startOf("month")
          .format("YYYY-MM-DDThh:mm:ss")
      ).getTime();
      const latestTwelveMonths = new Date(
        moment()
          .subtract(12, "month")
          .startOf("month")
          .format("YYYY-MM-DDThh:mm:ss")
      ).getTime();
      const now = new Date(moment().format("YYYY-MM-DDThh:mm:ss")).getTime();

      switch (timeline) {
        case "thisMonth":
          chart.value.zoomX(thisMonthBegin, now);
          break;
        case "latestMonths":
          chart.value.zoomX(latestMonthsBegin, latestMonthsEnd);
          break;
        case "Latest_7_days":
          chart.value.zoomX(latestSevenDays, now);
          break;
        case "Latest_3_months":
          chart.value.zoomX(latestThreeMonths, now);
          break;
        case "Latest_6_months":
          chart.value.zoomX(latestSixMonths, now);
          break;
        case "Latest_12_months":
          chart.value.zoomX(latestTwelveMonths, now);
          break;

        default:
      }
    };
    //取得月份
    let getMonthString = (front) => {
      let frontMonthString = moment()
        .subtract(front, "month")
        .startOf("month")
        .format("M");
      return frontMonthString + "月份";
    };

    const handleGetTokenReport = async () => {
      let request = {
        beginTime: moment()
          .subtract(12, "month")
          .startOf("month")
          .format("YYYY-MM-DDThh:mm:ssZ"),
        endTime: currentTime,
      };
      return await getTokenReport(currentSpaceGuid!, request);
    };

    const getReportChartData = async () => {
      let report = await handleGetTokenReport();
      let mainSeries: any = { name: "主票", data: [] };
      let invitationSeries: any = { name: "邀請票", data: [] };
      report.forEach((element) => {
        mainSeries.data.push([
          moment(element.endTime, "YYYY-MM-DDThh:mm:ssZ").valueOf(),
          element.mainTokens.length,
        ]);

        invitationSeries.data.push([
          moment(element.endTime, "YYYY-MM-DDThh:mm:ssZ").valueOf(),
          element.invitationSubTokens.length,
        ]);
      });
      series[0] = mainSeries;
      series[1] = invitationSeries;
    };

    const init = async () => {
      isLoading.value = true;
      await getReportChartData();
      updateData(selection);
      isLoading.value = false;
    };
    init();

    return {
      chartOptions,
      series,
      chart,
      updateData,
      getMonthString,
      communityOptions,
      communitySelected,
      isLoading,
    };
  },
});
