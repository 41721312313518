
import { defineComponent, ref } from "vue";
import { getPublishAnnouncements } from "@/core/services/api/announcement";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let announceQuantity = ref(0);

    //取得張貼中公告數量
    const getPublishLength = async () => {
      let announceQuantity = (await getPublishAnnouncements(currentSpaceGuid!))
        .length;
      return announceQuantity;
    };
    // const routerPush = (pushName) => {
    //   this.$router.push({ name: `${pushName}` });
    // }
    const init = async () => {
      announceQuantity.value = await getPublishLength();
    };
    init();

    return {
      announceQuantity,
    };
  },
});
