
import { defineComponent, ref } from "vue";
import { getMembersBySpace } from "@/core/services/api/members";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  setup() {
    let isLoading = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let membersCount = ref(0);

    const getMembersCount = async () => {
      let members = await getMembersBySpace(currentSpaceGuid!);
      membersCount.value = members.length;
    };

    const init = async () => {
      isLoading.value = true;
      await getMembersCount();
      isLoading.value = false;
    };
    init();

    return {
      isLoading,
      membersCount,
    };
  },
});
