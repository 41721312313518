export enum ReportItemType {
    Facility = "Facility", Equipment = "Equipment", Activity = "Activity"
}

export interface FinanceReportItem {
    "type": ReportItemType,
    "itemGuid": string,
    "name": string,
    "onlineAmount": number,
    "otherAmount": number
}