<template>
  <div
    class="container-fluid bg-white rounded p-0"
    style="min-height: 500px; max-height: 500px"
  >
    <div class="row justify-content-between">
      <div class="row col">
        <div class="col-6 my-6 ms-6 d-flex align-items-center">
          <h1 class="m-0">用戶訂單數</h1>
        </div>
      </div>
      <div class="col-2 d-flex justify-content-center align-items-center">
        <button
          v-show="!isLoading"
          type="button"
          class="
            btn btn-sm btn-icon btn-color-primary btn-active-primary
            border-0
            me-n3
          "
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="media/icons/duotune/general/gen052.svg" />
          </span>
        </button>
        <DashboardTimeFilter
          :beginTime="showTimeRange.beginTime"
          :endTime="showTimeRange.endTime"
          :currentCycle="showTimeRange.currentCycle"
          @filterByLatest="filterByLatest"
          @filterByLatestMonth="filterByLatestMonth"
        ></DashboardTimeFilter>
        <div
          v-show="isLoading"
          class="spinner-grow align-self-center text-primary"
          style="width: 1.5rem; height: 1.5rem"
          role="status"
        ></div>
      </div>
    </div>
    <div class="row px-6">
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle gs-0 gy-3">
          <!--begin::Table head-->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-140px"></th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index1) in showReport" :key="index1">
              <tr>
                <td>
                  <div class="symbol symbol-50px">
                    <img
                      v-if="item.avatar == ''"
                      src="media/avatars/blank.png"
                      alt=""
                    />
                    <img v-else :src="item.avatar" alt="" />
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    class="text-dark text-nowrap fw-bolder text-hover-primary mb-1 fs-6"
                    >{{ item.name }}&emsp;({{ item.paymentsLength }}筆訂單)</a
                  >
                  <span
                    v-if="item.name == ''"
                    class="text-dark text-nowrap fw-bolder mb-1 fs-6"
                    >未填寫姓名</span
                  >
                
                </td>
                <td>
                  <span class="text-muted text-nowrap fw-bold d-block fs-7">
                    訂單金額
                  </span>

                  <span class="text-muted text-nowrap fw-bold d-block fs-7">{{
                    item.amount
                  }}$</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import { getFilePath } from "@/core/utils/file";
import { getLocalTimeString } from "@/core/utils/timeString";
import moment from "moment";
import localstorage from "@/core/services/LocalstorageService";
import { getUserCashPaymentReport } from "@/core/services/api/report";
import DashboardTimeFilter from "@/components/dropdown/DashboardTimeFilter.vue";
import { getUserByGuid } from "@/core/services/api/user";
import { PhoneType } from "@/core/services/responsesModel/userExtend";

export default defineComponent({
  components: {
    DashboardTimeFilter,
  },
  setup() {
    let showReport: Array<any> = reactive([]);
    let isLoading = ref(false);
    //是否顯示詳細時間
    let isShowDetailedTime = false;
    //顯示的過濾時間
    let showTimeRange = reactive({
      //當前的篩選週期 X月,近7天， 近3個月, 近6個月, 近12個月
      currentCycle: "",
      beginTime: "",
      endTime: "",
    });
    let timeRange = {
      beginTime: "",
      endTime: "",
    };
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    let setFilterByLatest = async (latestTime, timeUnit, filterName) => {
      isLoading.value = true;
      isShowDetailedTime = true;
      timeRange = {
        beginTime: moment()
          .subtract(latestTime - 1, timeUnit)
          .startOf(timeUnit)
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      setShowTime(filterName);
      await setShowData();
      isLoading.value = false;
    };

    let setFilterByLatestMonth = async (monthString) => {
      isLoading.value = true;
      timeRange = {
        beginTime: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      isShowDetailedTime = false;

      setShowTime(monthString);
      await setShowData();
      isLoading.value = false;
    };

    //設置顯示時間
    let setShowTime = (filterName) => {
      showTimeRange.currentCycle = filterName;
      showTimeRange.beginTime = "";
      showTimeRange.endTime = "今日";
      let beginTime = moment(timeRange.beginTime);
      let endTime = moment(timeRange.endTime);
      let currentY = moment().format("YYYY");
      let current = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      if (beginTime.isBefore(currentY)) {
        showTimeRange.beginTime = beginTime.format("YYYY/M/D");
      } else {
        showTimeRange.beginTime = beginTime.format("M/D");
      }
      if (!endTime.isSame(current)) {
        showTimeRange.endTime = endTime.format("M/D");
      }
    };

    // 更新時間過濾
    let filterByLatest = async (filter) => {
      await setFilterByLatest(filter[0], filter[1], filter[2]);
    };
    let filterByLatestMonth = async (filter) => {
      await setFilterByLatestMonth(filter);
    };
    //取得用户支付报告
    const handleGetUserCashPaymentReport = async (timeRange) => {
      let report: any = [];
      await getUserCashPaymentReport(currentSpaceGuid!, timeRange).then(
        (res) => {
          report = res;
        }
      );
      return report;
    };
    // 根據userGuid查詢使用者資料
    const getUserbyUserGuid = async (userGuid) => {
      let request = {
        user: userGuid,
      };
      let userData: any = {};
      let userExtends = await getUserByGuid(request);
      let cellPhone: Array<string> = [];
      let telePhone: Array<string> = [];
      userExtends.user.phones.forEach((o) => {
        if (o.type == PhoneType.CellPhone) {
          cellPhone.push(o.countryCode + o.nationalNumber);
        } else if (o.type == PhoneType.Telephone) {
          telePhone.push(o.countryCode + o.nationalNumber);
        }
      });
      if (userExtends.user.birthday == "0001-01-01T00:00:00.000Z") {
        userData.birthday = "";
      } else {
        userData.birthday = moment(userExtends.user.birthday).format(
          "YYYY/MM/DD"
        );
      }
      let avatar = userExtends.user.theme.avatar;
      if (avatar !== "" && avatar !== undefined && avatar !== null) {
        userData.avatar = getFilePath(avatar);
      } else {
        userData.avatar = "";
      }
      userData.name = userExtends.user.name;
      userData.email = userExtends.user.email;
      userData.cellPhone = cellPhone;
      userData.telePhone = telePhone;
      userData.jobTitle = userExtends.user.jobTitle;
      userData.company = userExtends.user.company;
      return userData;
    };

    //取得顯示資料
    const setShowData = async () => {
      // let timeRange = {
      //   beginTime: this.$moment().startOf(cycle).format("YYYY-MM-DDTHH:mm:ssZ"),
      //   endTime: this.currentTime,
      // };
      let report = (await handleGetUserCashPaymentReport(timeRange)).slice(
        0,
        6
      );
      let showData: any = [];
      for (const r of report) {
        let userData = await getUserbyUserGuid(r.user);
        showData.push({
          userGuid: r.user,
          avatar: userData.avatar,
          name: userData.name,
          amount: r.amount,
          paymentsLength: r.payments.length,
        });
      }

      showData.sort(function (a, b) {
        return a.amount - b.amount;
      });
      showData.sort(function (a, b) {
        return a.paymentsLength - b.paymentsLength;
      });

      showData.reverse();
      showReport.splice(0, showReport.length, ...showData);
    };

    const init = async () => {
      await setFilterByLatest(7, "days", "近7天");
      isLoading.value = false;
    };
    init();

    return {
      showTimeRange,
      filterByLatest,
      filterByLatestMonth,
      isLoading,
      showReport,
    };
  },
});
</script>

<style>
</style>