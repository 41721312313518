
import { defineComponent, ref } from "vue";
import { getPackageReport } from "@/core/services/api/report";
import localstorage from "@/core/services/LocalstorageService";
import moment from "moment";

export default defineComponent({
  setup() {
    let isAlliance =
      localstorage.getCurrentSpaces()!.alliances.length > 0 ? true : false;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let timeRange = {
      beginTime: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      endTime: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
    };
    // let isAlliance = Vue.$ls.get("currentSpace").alliances.length>0? true:false,
    //當日新包裹數量
    let newPackagesLength = ref(0);
    //未領取包裹數量
    let unReceivePackagesLength = ref(0);
    //代轉出數量
    let unDepartPackagesLength = ref(0);
    //轉送中數量
    let departedPackagesLength = ref(0);
    //代轉入數量
    let unArrivalPackagesLength = ref(0);

    const init = async () => {
      await getPackageReport(currentSpaceGuid!, timeRange).then((res) => {
        newPackagesLength.value = res.newPackages.length;
        unReceivePackagesLength.value = res.unReceivePackages.length;
        unDepartPackagesLength.value = res.unDepartPackages.length;
        departedPackagesLength.value = res.departedPackages.length;
        unArrivalPackagesLength.value = res.unArrivalPackages.length;
      });
    };
    init();

    return {
      isAlliance,
      newPackagesLength,
      unReceivePackagesLength,
      unDepartPackagesLength,
      departedPackagesLength,
      unArrivalPackagesLength,
    };
  },
});
