<template>
  <div
    class="container-fluid bg-white rounded p-0"
    style="min-height: 500px; max-height: 500px"
  >
    <div class="row justify-content-between">
      <div class="row col">
        <div class="col-6 my-6 ms-6 d-flex align-items-center">
          <h1 class="m-0">新進會員</h1>
        </div>
        <div class="col d-flex justify-content-end">
          <div
            v-show="isLoading"
            class="spinner-grow align-self-center text-primary"
            style="width: 1.5rem; height: 1.5rem"
            role="status"
          ></div>
        </div>
      </div>
    </div>
    <div class="row px-6">
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle gs-0 gy-3">
          <!--begin::Table head-->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-140px"></th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index1) in members" :key="index1">
              <tr>
                <td>
                  <div class="symbol symbol-50px">
                    <img
                      v-if="item.avatar == ''"
                      src="media/avatars/blank.png"
                      alt=""
                    />
                    <img v-else :src="item.avatar" alt="" />
                  </div>
                </td>
                <td>
                  <a
                    href="#"
                    class="
                      text-dark text-nowrap
                      fw-bolder
                      text-hover-primary
                      mb-1
                      fs-6
                    "
                    >{{ item.name }}</a
                  >
                  <span
                    v-if="item.name == ''"
                    class="text-dark text-nowrap fw-bolder mb-1 fs-6"
                    >未填寫姓名</span
                  >
                  <span class="text-muted text-nowrap fw-bold d-block fs-7">{{
                    item.jobTitle
                  }}</span>
                  <span
                    v-if="item.jobTitle == ''"
                    class="text-muted text-nowrap fw-bold d-block fs-7"
                    >未填寫公司</span
                  >
                </td>
                <td>
                  <span class="text-muted text-nowrap fw-bold d-block fs-7">
                    加入時間
                  </span>

                  <span class="text-muted text-nowrap fw-bold d-block fs-7">{{
                    item.createTime
                  }}</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import { getMembersBySpace } from "@/core/services/api/members";
import { getUserByGuid } from "@/core/services/api/user";
import { PhoneType } from "@/core/services/responsesModel/userExtend";
import { getFilePath } from "@/core/utils/file";
import { getLocalTimeString } from "@/core/utils/timeString";
import moment from "moment";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  setup() {
    let isLoading = ref(false);
    let members: Array<any> = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    //根據userGuid查詢使用者資料
    // const getUserbyUserGuid = async (userGuid) => {
    //   let request = {
    //     user: userGuid,
    //   };
    //   let userData: any = {};
    //   let userExtends = await getUserByGuid(request);
    //   let cellPhone: Array<string> = [];
    //   let telePhone: Array<string> = [];
    //   userExtends.user.phones.forEach((o) => {
    //     if (o.type == PhoneType.CellPhone) {
    //       cellPhone.push(o.countryCode + o.nationalNumber);
    //     } else if (o.type == PhoneType.Telephone) {
    //       telePhone.push(o.countryCode + o.nationalNumber);
    //     }
    //   });
    //   if (userExtends.user.birthday == "0001-01-01T00:00:00.000Z") {
    //     userData.birthday = "";
    //   } else {
    //     userData.birthday = moment(userExtends.user.birthday).format(
    //       "YYYY/MM/DD"
    //     );
    //   }
    //   let avatar = userExtends.user.theme.avatar;
    //   if (avatar !== "" && avatar !== undefined && avatar !== null) {
    //     userData.avatar = getFilePath(avatar);
    //   } else {
    //     userData.avatar = "";
    //   }
    //   userData.name = userExtends.user.name;
    //   userData.email = userExtends.user.email;
    //   userData.cellPhone = cellPhone;
    //   userData.telePhone = telePhone;
    //   userData.jobTitle = userExtends.user.jobTitle;
    //   userData.company = userExtends.user.company;
    //   return userData;
    // };

    const setTableData = async () => {
      let spaceMembers = await getMembersBySpace(currentSpaceGuid!);
      console.log("spaceMembers", spaceMembers);
      members.splice(0);
      for (let i = 0; i < 6 && i < spaceMembers.length; i++) {
        console.log("spaceMembers[i]", spaceMembers[i]);
        let theme = spaceMembers[i].theme;
        let avatarImg;
        if (
          theme.avatar !== "" &&
          theme.avatar !== undefined &&
          theme.avatar !== null
        ) {
          avatarImg = getFilePath(theme.avatar);
        } else {
          avatarImg = "";
        }
        members.push({
          userGuid: spaceMembers[i].guid,
          name: spaceMembers[i].name,
          jobTitle: spaceMembers[i].jobTitle,
          createTime: getLocalTimeString(spaceMembers[i].joinSpaceTime),
          company: spaceMembers[i].company,
          avatar: avatarImg,
        });
      }
    };

    const init = async () => {
      isLoading.value = true;
      await setTableData();
      isLoading.value = false;
    };
    init();

    return {
      members,
      isLoading,
    };
  },
});
</script>

<style>
</style>