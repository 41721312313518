
import { defineComponent, reactive, ref } from "vue";
import { getMembersBySpace } from "@/core/services/api/members";
import { getUserByGuid } from "@/core/services/api/user";
import { PhoneType } from "@/core/services/responsesModel/userExtend";
import { getFilePath } from "@/core/utils/file";
import { getLocalTimeString } from "@/core/utils/timeString";
import moment from "moment";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  setup() {
    let isLoading = ref(false);
    let members: Array<any> = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    //根據userGuid查詢使用者資料
    // const getUserbyUserGuid = async (userGuid) => {
    //   let request = {
    //     user: userGuid,
    //   };
    //   let userData: any = {};
    //   let userExtends = await getUserByGuid(request);
    //   let cellPhone: Array<string> = [];
    //   let telePhone: Array<string> = [];
    //   userExtends.user.phones.forEach((o) => {
    //     if (o.type == PhoneType.CellPhone) {
    //       cellPhone.push(o.countryCode + o.nationalNumber);
    //     } else if (o.type == PhoneType.Telephone) {
    //       telePhone.push(o.countryCode + o.nationalNumber);
    //     }
    //   });
    //   if (userExtends.user.birthday == "0001-01-01T00:00:00.000Z") {
    //     userData.birthday = "";
    //   } else {
    //     userData.birthday = moment(userExtends.user.birthday).format(
    //       "YYYY/MM/DD"
    //     );
    //   }
    //   let avatar = userExtends.user.theme.avatar;
    //   if (avatar !== "" && avatar !== undefined && avatar !== null) {
    //     userData.avatar = getFilePath(avatar);
    //   } else {
    //     userData.avatar = "";
    //   }
    //   userData.name = userExtends.user.name;
    //   userData.email = userExtends.user.email;
    //   userData.cellPhone = cellPhone;
    //   userData.telePhone = telePhone;
    //   userData.jobTitle = userExtends.user.jobTitle;
    //   userData.company = userExtends.user.company;
    //   return userData;
    // };

    const setTableData = async () => {
      let spaceMembers = await getMembersBySpace(currentSpaceGuid!);
      console.log("spaceMembers", spaceMembers);
      members.splice(0);
      for (let i = 0; i < 6 && i < spaceMembers.length; i++) {
        console.log("spaceMembers[i]", spaceMembers[i]);
        let theme = spaceMembers[i].theme;
        let avatarImg;
        if (
          theme.avatar !== "" &&
          theme.avatar !== undefined &&
          theme.avatar !== null
        ) {
          avatarImg = getFilePath(theme.avatar);
        } else {
          avatarImg = "";
        }
        members.push({
          userGuid: spaceMembers[i].guid,
          name: spaceMembers[i].name,
          jobTitle: spaceMembers[i].jobTitle,
          createTime: getLocalTimeString(spaceMembers[i].joinSpaceTime),
          company: spaceMembers[i].company,
          avatar: avatarImg,
        });
      }
    };

    const init = async () => {
      isLoading.value = true;
      await setTableData();
      isLoading.value = false;
    };
    init();

    return {
      members,
      isLoading,
    };
  },
});
