
import { defineComponent, reactive, ref } from "vue";
import moment from "moment";
import { getFinanceReport } from "@/core/services/api/report";
import { ReportItemType } from "@/core/services/responsesModel/financeReportItem";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  setup() {
    let isLoading = ref(false);
    ///////////顯示筆數(多少條)//////
    let itemCount = 12;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    //設置顯示日期
    const getShowDate = (dataCycleCount) => {
      let showDate: any = [];
      for (let i = dataCycleCount - 1; i >= 0; i--) {
        let showTime = moment()
          .subtract(i, "month")
          .startOf("month")
          .format("MMMM");
        if (showTime == "一月") {
          showDate.push(
            moment().subtract(i, "month").startOf("month").format("YYYY")
          );
        } else {
          showDate.push(
            moment().subtract(i, "month").startOf("month").format("MMMM'YY")
          );
        }
      }
      return showDate;
    };
    let barChartOptions = {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        categories: getShowDate(12),
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " K";
          },
        },
      },
    };

    let facilitySeries = reactive([
      {
        name: "線上",
        data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      },
      {
        name: "其他",
        data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      },
    ]);

    let equipmentSeries = reactive([
      {
        name: "線上",
        data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      },
      {
        name: "其他",
        data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      },
    ]);

    //取得期間全設施總額
    const getTimeRangeAmount = async (timeRange) => {
      let total = {
        // facilityTotalAmount: 0,
        facilityTotalOnline: 0,
        facilityTotalOther: 0,
        // equipmentTotalAmount: 0,
        equipmentTotalOnline: 0,
        equipmentTotalOther: 0,
      };
      let financeReport = await getFinanceReport(currentSpaceGuid!, timeRange);
      financeReport.forEach((x) => {
        if (x.type == ReportItemType.Facility) {
          total.facilityTotalOther = total.facilityTotalOther + x.otherAmount;
          total.facilityTotalOnline =
            total.facilityTotalOnline + x.onlineAmount;
          // total.facilityTotalAmount =
          //   total.facilityTotalAmount + x.onlineAmount + x.otherAmount;
        } else if (x.type == ReportItemType.Equipment) {
          // total.equipmentTotalAmount =
          //   total.equipmentTotalAmount + x.onlineAmount + x.otherAmount;
          total.equipmentTotalOnline =
            total.equipmentTotalOnline + x.onlineAmount;
          total.equipmentTotalOther = total.equipmentTotalOther + x.otherAmount;
        }
      });
      return total;
    };

    //取得設施顯示資料
    const getShowData = async (timeCycleCount) => {
      let showData: any = {
        // showFacilityAmount: [],
        showFacilityOnline: [],
        showFacilityOther: [],
        // showEquipmentAmount: [],
        showEquipmentOnline: [],
        showEquipmentOther: [],
      };
      for (let i = timeCycleCount - 1; i >= 0; i--) {
        let timeRange = getTimeRange(i);
        let showTotal = await getTimeRangeAmount(timeRange);
        // showData.showFacilityAmount.push(showTotal.facilityTotalAmount / 1000);
        showData.showFacilityOnline.push(showTotal.facilityTotalOnline / 1000);
        showData.showFacilityOther.push(showTotal.facilityTotalOther / 1000);
        // showData.showEquipmentAmount.push(
        //   showTotal.equipmentTotalAmount / 1000
        // );
        showData.showEquipmentOnline.push(
          showTotal.equipmentTotalOnline / 1000
        );
        showData.showEquipmentOther.push(showTotal.equipmentTotalOther / 1000);
      }

      return showData;
    };

    //取得前x月的timeRange
    const getTimeRange = (month) => {
      let timeRange = {};
      timeRange = {
        beginTime: moment()
          .subtract(month, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment()
          .subtract(month, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      return timeRange;
    };

    //設置顯示資料
    const putShowData = (name, purpose, data) => {
      let series: any = [];
      if (purpose == "Facility") {
        series = facilitySeries;
      } else if (purpose == "Equipment") {
        series = equipmentSeries;
      }
      series.forEach((item, index, arr) => {
        if (item.name == name) {
          arr[index].data = data;
        }
      });
      let newSeries = JSON.parse(JSON.stringify(series));
      if (purpose == "Facility") {
        facilitySeries.splice(0, facilitySeries.length, ...newSeries);
      } else if (purpose == "Equipment") {
        equipmentSeries.splice(0, equipmentSeries.length, ...newSeries);
      }
    };

    const init = async () => {
      isLoading.value = true;
      let putData = await getShowData(itemCount);
      putShowData("線上", "Facility", putData.showFacilityOnline);
      putShowData("其他", "Facility", putData.showFacilityOther);
      putShowData("線上", "Equipment", putData.showEquipmentOnline);
      putShowData("其他", "Equipment", putData.showEquipmentOther);
      isLoading.value = false;
    };
    init();

    return {
      facilitySeries,
      equipmentSeries,
      barChartOptions,
      isLoading,
    };
  },
});
