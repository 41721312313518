import ApiService from "@/core/services/ApiService";
import { CurrentUsingReportItem } from "@/core/services/responsesModel/currentUsingReportItem"
import { PaymentReport } from "@/core/services/responsesModel/paymentReport"
import { TokenReportItem } from "@/core/services/responsesModel/tokenReportItem"
import { FinanceReportItem } from "@/core/services/responsesModel/financeReportItem"
import { BookingReportItem } from "@/core/services/responsesModel/bookingReportItem"
import { TokenStatusReport } from "@/core/services/responsesModel/tokenStatusReport"
import { PackageReport } from "@/core/services/responsesModel/packageReport"
import { UserCashPaymentReportItem } from "@/core/services/responsesModel/userCashPaymentReportItem"

export function getCurrentUsingReport(spaceGuid: string): Promise<Array<CurrentUsingReportItem>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/CurrentUsingReport`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getPaymentReport(spaceGuid: string, request): Promise<PaymentReport> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/PaymentReport`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getTokenReport(spaceGuid: string, request): Promise<Array<TokenReportItem>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/TokenReport`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getFinanceReport(spaceGuid: string, request): Promise<Array<FinanceReportItem>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/FinanceReport`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getFacilityReport(spaceGuid: string, request): Promise<Array<BookingReportItem>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/FacilityReport`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getEquipmentReport(spaceGuid: string, request): Promise<Array<BookingReportItem>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/EquipmentReport`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getTokenStatusReport(spaceGuid: string, request): Promise<TokenStatusReport> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/TokenStatusReport`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getPackageReport(spaceGuid: string, request): Promise<PackageReport> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/PackageReport`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getUserCashPaymentReport(spaceGuid: string, request): Promise<Array<UserCashPaymentReportItem>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/UserCashPaymentReport`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}