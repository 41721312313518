<template>
  <div
    class="container-fluid bg-white rounded p-0"
    style="min-height: 500px; max-height: 500px"
  >
    <div class="row justify-content-between">
      <div class="row col">
        <div class="col-6 my-6 ms-6 d-flex align-items-center">
          <h1 class="m-0">設備使用率</h1>
        </div>
      </div>

      <div class="col-2 d-flex justify-content-center align-items-center">
        <button
          v-show="!isLoading"
          type="button"
          class="
            btn btn-sm btn-icon btn-color-primary btn-active-primary
            border-0
            me-n3
          "
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="media/icons/duotune/general/gen052.svg" />
          </span>
        </button>
        <DashboardTimeFilter
          :beginTime="showTimeRange.beginTime"
          :endTime="showTimeRange.endTime"
          :currentCycle="showTimeRange.currentCycle"
          @filterByLatest="filterByLatest"
          @filterByLatestMonth="filterByLatestMonth"
        ></DashboardTimeFilter>
        <div
          v-show="isLoading"
          class="spinner-grow align-self-center text-primary"
          style="width: 1.5rem; height: 1.5rem"
          role="status"
        ></div>
      </div>
    </div>
    <!-- <hr class="m-0" /> -->
    <div class="my-2" style="max-height: 410px; overflow-y:auto; overflow-x:hidden;">
      <div class="card-body">
        <div>
          <!--begin::Table-->
          <table
            class="
              table table-row-dashed table-row-gray-200
              align-middle
              gs-0
              gy-4
            "
          >
            <!--begin::Table head-->
            <thead>
              <tr class="border-0">
                <th class="p-0 w-50px"></th>
                <th class="p-0 min-w-150px"></th>
              </tr>
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody>
              <tr v-for="data in showData" :key="data">
                <td style="width: 30px">
                  <img
                    :src="data.img"
                    class="facilityImg"
                    style="width: 50px; height: 50px; border-radius: 10px"
                    alt=""
                  />
                </td>
                <td class="container-fluid">
                  <div class="row">
                    <div class="col fs-4">
                      {{ data.title }}
                    </div>
                    <div class="col-3 text-end" >
                         <span style="color: #b5b5c3; font-size: 17px">{{
                      data.percentage
                    }}</span>
                    </div>
                   
                  </div>
                  <div class="d-flex flex-column w-100 mr-2">
                      <div
                        class="
                          d-flex
                          align-items-center
                          justify-content-between
                          mb-2
                        "
                      ></div>
                      <div class="progress progress-xs w-100">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="{ width: data.percentage }"
                          v-bind:class="`bg-${data.class}`"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  <!-- <div class="row">
                    <el-progress
                      class="col"
                      :text-inside="false"
                      :stroke-width="26"
                      :percentage="data.ratio"
                    ></el-progress>
                  </div> -->
                </td>
              </tr>
            </tbody>
            <!--end::Table body-->
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, onMounted } from "vue";
import moment from "moment";
import localstorage from "@/core/services/LocalstorageService";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getEquipmentReport } from "@/core/services/api/report";
import DashboardTimeFilter from "@/components/dropdown/DashboardTimeFilter.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { FacilityExtend } from "@/core/services/responsesModel/facilityExtend";
import { getFilePath } from "@/core/utils/file";

export default defineComponent({
  components: {
    DashboardTimeFilter,
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
    let showData: Array<any> = reactive([]);
    let facilities: Array<FacilityExtend>;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let currentTime = moment().format("YYYY-MM-DDThh:mm:ssZ");
    let reportData = [];
    let rankingData = reactive([]);
    let communitySelected = ref("all");
    let communityOptions: any = reactive([]);
    let isLoading = ref(false);
    //是否顯示詳細時間
    let isShowDetailedTime = false;
    //顯示的過濾時間
    let showTimeRange = reactive({
      //當前的篩選週期 X月,近7天， 近3個月, 近6個月, 近12個月
      currentCycle: "",
      beginTime: "",
      endTime: "",
    });
    let timeRange = {
      beginTime: "",
      endTime: "",
    };

    let setFilterByLatest = async (latestTime, timeUnit, filterName) => {
     isLoading.value = true;
     isShowDetailedTime = true;
      timeRange = {
        beginTime: moment()
          .subtract(latestTime - 1, timeUnit)
          .startOf(timeUnit)
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      setShowTime(filterName);
      await setShowData();
      isLoading.value = false;
    };

    let setFilterByLatestMonth = async (monthString) => {
      isLoading.value = true;
      timeRange = {
        beginTime: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endTime: moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      isShowDetailedTime = false;

      setShowTime(monthString);
      await setShowData();
      isLoading.value = false;
    };

    //設置顯示時間
    let setShowTime = (filterName) => {
      showTimeRange.currentCycle = filterName;
      showTimeRange.beginTime = "";
      showTimeRange.endTime = "今日";
      let beginTime = moment(timeRange.beginTime);
      let endTime = moment(timeRange.endTime);
      let currentY = moment().format("YYYY");
      let current = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      if (beginTime.isBefore(currentY)) {
        showTimeRange.beginTime = beginTime.format("YYYY/M/D");
      } else {
        showTimeRange.beginTime = beginTime.format("M/D");
      }
      if (!endTime.isSame(current)) {
        showTimeRange.endTime = endTime.format("M/D");
      }
    };

    //取得月份
    let getMonthString = (front) => {
      let frontMonthString = moment()
        .subtract(front, "month")
        .startOf("month")
        .format("M");
      return frontMonthString + "月份";
    };

    // 更新時間過濾
    let filterByLatest = async (filter) => {
      await setFilterByLatest(filter[0], filter[1], filter[2]);
    };
    let filterByLatestMonth = async (filter) => {
      await setFilterByLatestMonth(filter);
    };

    const handleGetFacilityReport = async () => {
      return await getEquipmentReport(currentSpaceGuid!, timeRange);
    };

    const setShowData = async () => {
      let data: any = [];
      let facilityReport = await handleGetFacilityReport();
      for (let i = 0; i < facilityReport.length; i++) {
        let facility = getFacilityByGuid(facilityReport[i].itemGuid);
        //取得設施照片
        let imageGuid = facility.facility.theme.images[0];
        let image = "";
        if (imageGuid !== null && imageGuid !== undefined) {
          image = getFilePath(imageGuid);
        }
        //計算設施使用率
        let ratio = Math.ceil(
          (facilityReport[i].timeRangeReportItem.booking /
            facilityReport[i].timeRangeReportItem.total) *
            100
        );
        data.push({
          img: image,
          title: facilityReport[i].name,
          ratio: ratio,
          percentage: ratio + "%",
        });
      }
      data.sort(function (a, b) {
        // boolean false == 0; true == 1
        return a.ratio - b.ratio;
      });

      data.reverse();
      showData.splice(0, showData.length, ...data);
    };

    const setFacility = async () => {
      facilities = await getFacilityBySpace(currentSpaceGuid!);
    };

    const getFacilityByGuid = (guid: string) => {
      return facilities.filter((o) => o.facility.guid === guid)[0];
    };

    const init = async () => {
      await setFacility();
      await setFilterByLatest(7, "days", "近7天");
      await setShowData();
    };

    init();

    return {
      rankingData,
      communityOptions,
      showTimeRange,
      filterByLatest,
      filterByLatestMonth,
      communitySelected,
      showData,
      isLoading
    };
  },
});
</script>

<style scoped>
.loading {
  display: inline-block;
}

.loading i {
  font-size: 14px;
}

.piecesSold {
  color: rgb(86, 128, 182);
}
.totalRevenue {
  color: rgb(85, 170, 118);
}

.facilityImg {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
</style>