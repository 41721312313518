<template>
  <div
    class="row align-items-center bg-danger rounded text-white"
    style="min-height: 130px"
  >
  <div class="row">
    <span class="svg-icon svg-icon-2 svg-icon-white col-2 ps-7">
      <inline-svg src="media\svg\icons\Dashboard\Group 145.svg" />
    </span>
    <h3 class="col-7 mb-0 align-middle text-white">目前借出中的設備</h3>
    <div class="col d-flex justify-content-end">
       <div
          v-show="isLoading"
          class="spinner-grow align-self-center text-primary"
          style="width: 1.5rem; height: 1.5rem"
          role="status"
        ></div>
    </div>
   
  </div>
    
    <div class="col">
      <div class="row" style="height: 63px">
        <div class="col border-end">
          <div class="row">
            <div
              class="col-12 text-center"
              style="font-weight: 500; font-size: 28px"
            >
              {{ lend }}
            </div>
            <div class="col-12 text-center">物品借出中</div>
          </div>
        </div>
        <div class="col">
          <div
            class="col-12 text-center"
            style="font-weight: 500; font-size: 28px"
          >
            {{ overdue }}
          </div>
          <div class="col-12 text-center">逾期未歸還</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import localstorage from "@/core/services/LocalstorageService";
import { defineComponent, ref, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { getCurrentUsingReport } from "@/core/services/api/report";
import { ReportItemType } from "@/core/services/responsesModel/currentUsingReportItem";

export default defineComponent({
  setup() {
    let isLoading = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let lend = ref(0);
    let overdue = ref(0);

    const getUsingReport = async () => {
     await getCurrentUsingReport(currentSpaceGuid!).then((report) => {
        if (report !== null && report !== undefined) {
          let equipments = report.filter(
            (o) => o.type == ReportItemType.Equipment
          );
          let lendCount = 0;
          let usingCount = 0;
          for (const key in equipments) {
            lendCount = lendCount + equipments[key].booking;
            usingCount = usingCount + equipments[key].using;
          }
          lend.value = lendCount;
          if (usingCount > lendCount) {
            overdue.value = usingCount - lendCount;
          }
        }
      });
    };

    const init = async () => {
      isLoading.value = true;
      await getUsingReport();

      isLoading.value = false;
    };
    init();

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      isLoading,
      lend,
      overdue,
    };
  },
});
</script>

<style>
</style>