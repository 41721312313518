<template>
  <!-- 選單 -->
  <div
    class="
      menu
      menu-sub
      menu-sub-dropdown
      menu-column
      menu-rounded
      menu-gray-800
      menu-state-bg-light-primary
      fw-bold
      w-200px
      py-3
    "
    data-kt-menu="true"
  >
  <!-- title -->
    <div class="menu-item px-3">
      <div class="menu-content text-muted pb-2 px-3 fs-7">
        目前 : {{props.beginTime}}~{{props.endTime}}({{props.currentCycle}})
      </div>
    </div>
    <!-- title -->
    <div class="menu-item px-3">
      <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
        日期篩選
      </div>
    </div>

    <!-- -->
    <div class="menu-item px-3">
      <span class="menu-link px-3" @click="setFilterByLatest(7, 'days', '近7天')"> 近七天 </span>
    </div>

    <div class="menu-item px-3">
      <span class="menu-link px-3" @click="setFilterByLatest(1, 'month', '本月')"> 本月 </span>
    </div>

    <div class="menu-item px-3">
      <span class="menu-link px-3" @click="setFilterByLatestMonth(getMonthString(1))"> {{getMonthString(1)}} </span>
    </div>

    <div class="menu-item px-3">
      <span class="menu-link px-3" @click="setFilterByLatest(3, 'month', '近3月')"> 近3月 </span>
    </div>

    <div class="menu-item px-3">
      <span class="menu-link px-3" @click="setFilterByLatest(6, 'month', '近6月')"> 近6月 </span>
    </div>

    <div class="menu-item px-3">
      <span class="menu-link px-3" @click="setFilterByLatest(12, 'month', '近12月')"> 近12月 </span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import moment from "moment";

export default defineComponent({
  props:["beginTime","endTime","currentCycle"],
  setup(props, { attrs, emit, slots }) {
    let setFilterByLatest = (latestTime, timeUnit, filterName) => {
      let data = [latestTime, timeUnit, filterName]
      emit("filterByLatest", data);
    };

    let setFilterByLatestMonth = (filter) => {
      emit("filterByLatestMonth", filter);
    }

    //取得月份
   let getMonthString = (front) => {
      let frontMonthString = moment()
        .subtract(front, "month")
        .startOf("month")
        .format("M");
      return frontMonthString + "月份";
    }


    
    return {
      setFilterByLatest,
      setFilterByLatestMonth,
      getMonthString,
      props

    };
  },
});
</script>
