export interface UserTheme {
    "avatar": string
}

export enum PhoneType {
    CellPhone="CellPhone", Telephone="Telephone"
}

export interface Phone {
    "type": PhoneType,
    "countryCode": string,
    "nationalNumber": string,
    "extension": string,
    "description": string
}

export enum UserStatus {
    None="None", HasPassword="HasPassword", VerifyEmail="VerifyEmail", HasPhone="HasPhone"
}

export enum UserGender {
    NotProvided="NotProvided", Male="Male", Female="Female"
}

export interface User {
    "theme": UserTheme,
    "phones": Array<Phone>,
    "guid": string,
    "email": string,
    "password": string,
    "status": UserStatus,
    "createTime": string,
    "name": string,
    "gender": UserGender,
    "birthday": string,
    "description": string,
    "company": string,
    "jobTitle": string,
    "expertise": string
}

export interface UserExtend {
    "user": User,
    "groups": Array<string>
}