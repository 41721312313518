<template>
  <div
    class="row align-items-center bg-white rounded"
    style="min-height: 130px"
  >
    <span class="svg-icon svg-icon-2 col-1 ps-7">
      <inline-svg src="media\svg\icons\Dashboard\Group 145_2.svg" />
    </span>
    <h3 class="col mb-0 align-middle">公告</h3>
    <div class="row">
      <div class="col-xxl-5">
        <div style="text-align: center; font-size: 25px; color: #6993ff">
          <div
            style="
              text-align: center;
              font-size: 27px;
              color: #000000;
              font-weight: bold;
            "
          >
            {{ announceQuantity }}
          </div>
        </div>
        <div style="text-align: center; color: #a4a4a4">張貼中的公告</div>
      </div>
      <div class="col-xxl-2">
        <img
          src="media\svg\icons\Dashboard\Line 34.svg"
          style="display: block; margin: auto"
        />
      </div>
      <div class="col-xxl-5">
        <div style="text-align: center; font-size: 25px; color: #f64e60">
          <div
            style="
              text-align: center;
              font-size: 27px;
              color: #000000;
              font-weight: bold;
            "
          >
            0
          </div>
        </div>
        <div style="text-align: center; color: #a4a4a4">
          總瀏覽次數<i>(未開放)</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, ref } from "vue";
import { getPublishAnnouncements } from "@/core/services/api/announcement";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let announceQuantity = ref(0);

    //取得張貼中公告數量
    const getPublishLength = async () => {
      let announceQuantity = (await getPublishAnnouncements(currentSpaceGuid!))
        .length;
      return announceQuantity;
    };
    // const routerPush = (pushName) => {
    //   this.$router.push({ name: `${pushName}` });
    // }
    const init = async () => {
      announceQuantity.value = await getPublishLength();
    };
    init();

    return {
      announceQuantity,
    };
  },
});
</script>

<style>
</style>