import ApiService from "@/core/services/ApiService";
import { Token } from "@/core/services/responsesModel/token"

export function getValidTicket(spaceGuid: string, request): Promise<Array<Token>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Tokens/Valid`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export function getTokens(request): Promise<Array<Token>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Tokens/Query`, request).then(res => {
            resolve(res.data.tokens)
        }).catch(err => {
            reject(err)
        })
    })
}

// 查詢特殊保留
export function getSpecialReserve(request): Promise<Array<Token>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Tokens/QuerySpecialReserve`, request).then(res => {
            resolve(res.data.tokens)
        }).catch(err => {
            reject(err)
        })
    })
}

// 根據設施查詢特殊保留
export function getTokensByFacility(request): Promise<Array<Token>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Tokens/QueryByFacility`, request).then(res => {
            resolve(res.data.tokens)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增特殊保留
export function addSpecialReserves(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Tokens/AddSpecialReserves`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 修改Token備註
export function updateTokenRemark(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Tokens/UpdateRemark`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增特殊保留
export function rempveSpecialReserve(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Tokens/ReleaseSpecialReserve`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 查詢特殊通行
export function querySpecialAccess(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Tokens/QuerySpecialAccessBySpace`, request).then(res => {
            resolve(res.data.tokens)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增特殊通行
export function addSpecialAccess(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Tokens/AddSpecialAccess`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 釋放特殊通行備註
export function releaseSpecialAccess(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Tokens/ReleaseSpecialAccess`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}